/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'styles/index';

@layer base {
  body,
  html {
    @apply min-h-svh scroll-smooth bg-white text-neutral-low-pure antialiased;
  }

  * {
    @apply scrollbar-thin scrollbar-track-neutral-hight-light scrollbar-thumb-neutral-hight-dark scrollbar-track-rounded-lg scrollbar-thumb-rounded-lg;
  }
}

@layer utilities {
  ::selection {
    @apply bg-brand-primary-light text-neutral-hight-pure;
  }

  .action-round-button {
    @apply flex h-12 w-12 items-center justify-center rounded-3xl border border-black bg-white p-2 shadow-md transition-opacity duration-150 ease-in-out hover:bg-neutral-hight-light;
  }
}
